<template>
  <BaseButton
    :keyTextButtonTranslate="GetKeyTextButton"
    :handlerClick="goToSelectAddress"
  />
</template>
<script>
import { orderTypeStoreGetters } from "@/store/modules/orderType/constNames";

export default {
  name: "SelectAddressBtn",
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
  },
  methods: {
    goToSelectAddress() {
      this.$router.push({
        name: "ProfileAddresses",
        params: { isSelectable: true, fromRoute: this.$route.name },
      });
    },
  },
  computed: {
    IHaveSelectedAnAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
    },
    GetKeyTextButton() {
      return this.IHaveSelectedAnAddress ? "changeAddress" : "selectAddress";
    },
  },
};
</script>
